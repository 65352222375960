.footer {
  background-color: var(--second-color);
  color: #fff;
  text-align: center;
  font-weight: var(--font-semi);
  padding: 2rem 0;
}
.footer__title {
  font-size: 2rem;
  margin-bottom: var(--mb-4);
}
.footer__social {
  margin-bottom: var(--mb-4);
}
.footer__icon {
  font-size: 1.5rem;
  color: #fff;
  margin: 0 var(--mb-2);
}

/* a:hover {
  background-color: gray;

} */