.skills__container{
    row-gap: 2rem;
    text-align: center;
}
.skills__subtitle{
    margin-bottom: var(--mb-2);
}
.skills__text{
    margin-bottom: var(--mb-4);
}
.skills__data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: var(--font-semi);
    padding: .5rem 1rem;
    margin-bottom: var(--mb-4);
    border-radius: .5rem;
    box-shadow: 0 4px 25px rgba(14,36,49,.15);
}
.skills__icon{
    font-size: 2rem;
    margin-right: var(--mb-2);
    color: var(--first-color);
}
.skills__names{
    display: flex;
    align-items: center;
}
.skills__bar{
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--first-color);
    height: .25rem;
    border-radius: .5rem;
    z-index: var(--z-back);
}

.skills__react{
    width: 0%;
}
.skills__redux{
    width: 0%;
}
.skills__nodejs{
    width: 0%;
}
.skills__github{
    width: 0%;
}


/*=== Media Queries ===*/
@media screen and (min-width: 768px){
    .skills__container{
        grid-template-columns: repeat(2,1fr);
        align-items: center;
    }
    .skills__frontend{
        align-self: flex-start;
    }
    .skills__backend{
        align-self: flex-start;
    }
    .skills__others{
        align-self: flex-start;
    }
}