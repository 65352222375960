/*=== HOME ===*/
.home{
    height: calc(100vh - 3rem);
    row-gap: 1rem;
}
.home__profile{
    align-self: flex-end;
    margin-top: var(--mb-6);
}
.home__social{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    /* bottom: 0; */
    margin-right: var(--mb-2);
}
.home__social-icon{
    width: max-content;
    margin-bottom: var(--mb-2);
    margin-left: var(--mb-2);
    font-size: 1.5rem;
    color: var(--second-color);
}
.home__social-icon:hover{
    color: var(--first-color);
}
.home__img{
    justify-self: center;
}
.home__data{
    align-self: flex-start;
    margin-bottom: var(--mb-6);
}
.home__title{
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-5);
}
.home__title-color{
    color: var(--first-color);
}

/*=== Media Queries ===*/
@media screen and (min-width: 768px){
    .home{
        grid-template-columns: repeat(2,1fr);
        align-items: center;
        height: 100vh;
    }
    .home__profile{
        align-self: center;
    }
    .home__social{
        display: flex;
        flex-direction: row;
        position: inherit;
        margin-top: var(--mb-3);
        justify-content: center;
        margin-right: 0;
    }
    .home__social-icon{
        margin-bottom: 0;
        margin: 0 var(--mb-2);
    }
    .home__data{
        align-self: center;
        justify-content: center;
    }
    .home__title{
        font-size: var(--big-font-size);
        margin-bottom: var(--mb-5);
    }
}

@media screen and (min-width: 1024px){
    .home__img{
        right: 10%;
    }
}
