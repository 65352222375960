
/*===== LAYOUT =====*/
.l-header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(146,161,176,.15);
}

/*===== NAV =====*/
.nav{
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: var(--font-semi);
}
@media screen and (max-width: 768px) {
    .nav__menu {
        position: fixed;
        top: var(--header-height);
        right: -100%;
        width: 80%;
        height: 100%;
        padding: 2rem;
        background-color: var(--second-color);
        transition: .5s;
    }
}
.nav__item{
    margin-bottom: var(--mb-4);
}
.nav__link{
    position: relative;
    color: #fff;
}
.nav__link:hover{
    position: relative;
}
.nav__link:hover::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}
.nav__logo{
    color: var(--second-color);
}
.nav__toggle{
    color: var(--second-color);
    font-size: 1.5rem;
    cursor: pointer;
}

/*Active menu*/
.active::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 0.18rem;
    left: 0;
    top: 2rem;
    background-color: var(--first-color);
}
  
/*=== Show menu mobile ===*/
.show{
    right: 0;
}

  /*=== Media Queries ===*/
@media screen and (min-width: 768px){
    .nav{
        height: calc(var(--header-height) + 1rem);
    }
    .nav__list{
        display: flex;
        padding-top: 0;
    }
    .nav__item{
        margin-left: var(--mb-6);
        margin-bottom: 0;
    }
    .nav__toggle{
        display: none;
    }
    .nav__link{
        color: var(--second-color);
    }
}